import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/LoginOriginal'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const EpiControlLayout = React.lazy(() => import('./layout/EpiControlLayout'))
const Webcam = React.lazy(() => import('./views/peoples/Webcam'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<EpiControlLayout />} />
            <Route exact path="/webcam" name="Login Page" element={<Webcam />} />
            {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/entrega-epi" name="Entrega de E.P.I" element={<EpiControlLayout />} />
            <Route exact path="/entrega-epi/:id" name="Entrega de E.P.I" element={<EpiControlLayout />} />
            <Route exact path="/devolucao-epi" name="Devolução de E.P.I" element={<EpiControlLayout />} />
            <Route exact path="/devolucao-epi/:id" name="Devolução de E.P.I" element={<EpiControlLayout />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
